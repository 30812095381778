import { BrandConfig } from './model'

const brandConfig: BrandConfig = {
  BRAND_ID: 'zrad',
  BRAND_NAME: 'Zradio',
  APP_ID: 'io.zrad',
  LOCALE: 'nl-NL',
  WEB_URL: 'https://zrad.io',
  IMAGE_BASE_URL: 'https://static.zrad.io/images/',
  SITE_TITLE: 'Z Radio',
  GA_UA_ID: 'UA-73136876-4',
  FIREBASE_APP_ID: '1:72044832975:web:abf101c5b12204fb7aefba'
}

export default brandConfig
